import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { richTextParseOptions } from "modules/affording-pacbay/AffordingPacBay.config";
import React from "react";

export const FactorsColumn = ({ factors }) => {
  return (
    <div>
      {factors.map(({ node: factor }) => {
        const { id, heading, text } = factor;
        return (
          <article key={id}>
            <h6 style={{ marginBottom: "0" }}>{heading}</h6>
            {documentToReactComponents(
              JSON.parse(text.raw),
              richTextParseOptions
            )}
          </article>
        );
      })}
    </div>
  );
};
