import { AccordionContainer } from "components/accordion-container/AccordionContainer.component";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";

export const TuitionFaqs = () => {
  const {
    allContentfulFaq: { edges: tuitionFaqs },
  } = useStaticQuery(graphql`
    query TuitionFaqsQuery {
      allContentfulFaq(
        filter: { enabled: { eq: true }, type: { eq: "tuition" } }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            id
            question
            answer {
              raw
            }
          }
        }
      }
    }
  `);

  return <AccordionContainer title="Tuition FAQs" faqs={tuitionFaqs} />;
};
