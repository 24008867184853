import {
  FINANCIAL_AID,
  QUESTION_MINIMIZE_BREAKPOINT,
} from "components/constants";
import { StackedCallToAction } from "components/stacked-call-to-action/StackedCallToAction.component";
import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React from "react";
import linksService from "services/links.service";

export const AffordingPacBayCta = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= QUESTION_MINIMIZE_BREAKPOINT;

  return (
    <StackedCallToAction
      headerText={
        isMobile ? FINANCIAL_AID.HEADER.MOBILE : FINANCIAL_AID.HEADER.DESKTOP
      }
      paragraphText={
        isMobile
          ? FINANCIAL_AID.PARAGRAPH.MOBILE
          : FINANCIAL_AID.PARAGRAPH.DESKTOP
      }
      buttonObj={{
        label: FINANCIAL_AID.BUTTON_LABEL.DESKTOP,
        icon: SVGIcons.Apply,
        link: linksService.applyToFAST(),
        newTab: true,
      }}
    />
  );
};
