import { ImageHeading } from "components/page-specific-components/schools/components/image-heading/ImageHeading.component";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export const AffordingPacBayHeading = () => {
  return (
    <ImageHeading heading="Affording PacBay">
      <StaticImage
        src="../../../../images/jpg/affording_pacbay_heading.jpg"
        alt="two students studying together"
        placeholder="blurred"
        layout="constrained"
      />
    </ImageHeading>
  );
};
