import {
  ANNUAL,
  ANNUAL_ABBR,
  DOMESTIC_HS_ANNUAL_TUITION,
  DOMESTIC_HS_MONTHLY_TUITION,
  DOMESTIC_MS_ANNUAL_TUITION,
  DOMESTIC_MS_MONTHLY_TUITION,
  INTERNATIONAL_HS_ANNUAL_TUITION,
  INTERNATIONAL_HS_MONTHLY_TUITION,
  INTERNATIONAL_MS_ANNUAL_TUITION,
  INTERNATIONAL_MS_MONTHLY_TUITION,
  MONTHLY,
  MONTHLY_ABBR,
} from "./TuitionTables.constants";

export const getDomesticTuitionConfig = (isMobile) => [
  {
    heading: isMobile ? ANNUAL_ABBR : ANNUAL,
    highSchool: DOMESTIC_HS_ANNUAL_TUITION,
    middleSchool: DOMESTIC_MS_ANNUAL_TUITION,
  },
  {
    heading: isMobile ? MONTHLY_ABBR : MONTHLY,
    highSchool: DOMESTIC_HS_MONTHLY_TUITION,
    middleSchool: DOMESTIC_MS_MONTHLY_TUITION,
  },
];

export const getInternationalTuitionConfig = (isMobile) => [
  {
    heading: isMobile ? ANNUAL_ABBR : ANNUAL,
    highSchool: INTERNATIONAL_HS_ANNUAL_TUITION,
    middleSchool: INTERNATIONAL_MS_ANNUAL_TUITION,
  },
  {
    heading: isMobile ? MONTHLY_ABBR : MONTHLY,
    highSchool: INTERNATIONAL_HS_MONTHLY_TUITION,
    middleSchool: INTERNATIONAL_MS_MONTHLY_TUITION,
  },
];
