import { RELATED_PAGES_LABELS } from "components/constants";
import { SEO } from "components/seo/SEO.component";
import { useRelatedPages } from "hooks/relatedPages.hook";
import React from "react";
import linksService from "services/links.service";
import { AffordingPacBay as AffordingPacBayModule } from "modules/affording-pacbay/AffordingPacBay.component";

const AffordingPacBay = () => {
  useRelatedPages([
    { label: RELATED_PAGES_LABELS.OUR_STORY, link: linksService.ourStory() },
    {
      label: RELATED_PAGES_LABELS.HOW_TO_APPLY,
      link: linksService.howToApply(),
    },
  ]);

  return (
    <>
      <SEO title="Affording PacBay" />
      <AffordingPacBayModule />
    </>
  );
};

export default AffordingPacBay;
