import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { FactorsColumn } from "./components/factors-column/FactorsColumn.component";

export const Factors = () => {
  const {
    allContentfulAffordingPacBayFactors: { edges: factors },
  } = useStaticQuery(graphql`
    query AffordingPacBayFactorsQuery {
      allContentfulAffordingPacBayFactors(sort: { fields: order, order: ASC }) {
        edges {
          node {
            id
            heading
            text {
              raw
            }
          }
        }
      }
    }
  `);

  const firstIndex = 0;
  const midpointIndex = factors.length / 2;
  const firstColumnFactors = factors.slice(firstIndex, midpointIndex);
  const secondColumnFactors = factors.slice(midpointIndex, factors.length);

  return (
    <section className="factors-wrapper">
      <FactorsColumn factors={firstColumnFactors} />
      <FactorsColumn factors={secondColumnFactors} />
    </section>
  );
};
