import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import { Link } from "components/link/Link.component";
import React from "react";

export const richTextParseOptions = {
  renderNode: {
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="nested-paragraphs-no-margin">{children}</li>
    ),
    [INLINES.HYPERLINK]: (node, children) => {
      return (
        <Link to={node.data.uri} newTab>
          {children}
        </Link>
      );
    },
  },
};
