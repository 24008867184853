export const ANNUAL_ABBR = "An.";
export const ANNUAL = "Annual";
export const MONTHLY_ABBR = "M.";
export const MONTHLY = "Monthly (12 payments)";

// Domestic Tuition
export const DOMESTIC_HS_ANNUAL_TUITION = "$24,990";
export const DOMESTIC_MS_ANNUAL_TUITION = "$19,950";
export const DOMESTIC_HS_MONTHLY_TUITION = "$2,103/mo. ($25,236 total)";
export const DOMESTIC_MS_MONTHLY_TUITION = "$1,683/mo. ($20,196 total)";

// International Tuition
export const INTERNATIONAL_HS_ANNUAL_TUITION = "$32,215";
export const INTERNATIONAL_MS_ANNUAL_TUITION = "$23,478";
export const INTERNATIONAL_HS_MONTHLY_TUITION = "$2,705/mo. ($32,460 total)";
export const INTERNATIONAL_MS_MONTHLY_TUITION = "$1,977/mo.  ($23,724 total)";
