import React from "react";
import { Factors } from "./components/factors/Factors.component";
import { Opening } from "./components/opening/Opening.component";

export const AffordingPacBayBody = () => {
  return (
    <div className="layout">
      <Opening />
      <Factors />
    </div>
  );
};
