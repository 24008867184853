import { useWindowWidth } from "hooks/windowWidth.hook";
import React from "react";
import { QUESTION_MINIMIZE_BREAKPOINT } from "components/constants";
import { TuitionTable } from "./components/tuition-table/TuitionTable.component";
import {
  getDomesticTuitionConfig,
  getInternationalTuitionConfig,
} from "./TuitionTables.config";

export const TuitionTables = () => {
  const windowWidth = useWindowWidth();
  const isMobile = windowWidth <= QUESTION_MINIMIZE_BREAKPOINT;

  const domesticTuitionConfig = getDomesticTuitionConfig(isMobile);
  const internationalTuitionConfig = getInternationalTuitionConfig(isMobile);

  return (
    <div className="tuition-tables-wrapper">
      <TuitionTable
        tuitionType="Domestic"
        tuitionConfig={domesticTuitionConfig}
      />
      <TuitionTable
        tuitionType="International"
        tuitionConfig={internationalTuitionConfig}
      />
    </div>
  );
};
