import React from "react";

export const TUITION_COLUMN_HEADINGS = {
  BLANK: "",
  HIGH_SCHOOL: "High School",
  MIDDLE_SCHOOL: "Middle School",
};

const TUITION_YEAR = "2024-2025";
const TUITION = "Tuition";
const INCLUDING_FEES = "(including fees)";
export const GET_TABLE_HEADING = (tuitionType) => (
  <>
    {TUITION_YEAR} {tuitionType} {TUITION} <br />
    {INCLUDING_FEES}
  </>
);
