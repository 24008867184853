import React from "react";
import {
  GET_TABLE_HEADING,
  TUITION_COLUMN_HEADINGS,
} from "./TuitionTable.constants";

export const TuitionTable = ({ tuitionType, tuitionConfig }) => {
  const { BLANK, HIGH_SCHOOL, MIDDLE_SCHOOL } = TUITION_COLUMN_HEADINGS;

  return (
    <div>
      <h4 className="no-margin" style={{ margin: "0.5rem" }}>
        {GET_TABLE_HEADING(tuitionType)}
      </h4>
      <table>
        <thead>
          <tr>
            <th scope="col">{BLANK}</th>
            <th scope="col">{HIGH_SCHOOL}</th>
            <th scope="col">{MIDDLE_SCHOOL}</th>
          </tr>
        </thead>
        <tbody>
          {tuitionConfig.map(({ heading, highSchool, middleSchool }) => {
            return (
              <tr className="no-alternate">
                <th>{heading}</th>
                <td>{highSchool}</td>
                <td>{middleSchool}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
