import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { graphql, useStaticQuery } from "gatsby";
import { richTextParseOptions } from "modules/affording-pacbay/AffordingPacBay.config";
import React from "react";

export const Opening = () => {
  const {
    allContentfulAffordingPacBayOpening: {
      edges: {
        0: { node: opening },
      },
    },
  } = useStaticQuery(graphql`
    query AffordingPacBayOpeningQuery {
      allContentfulAffordingPacBayOpening(limit: 1) {
        edges {
          node {
            id
            heading
            text {
              raw
            }
          }
        }
      }
    }
  `);

  return (
    <section className="affording-pacbay-opening">
      <div>
        <h4>{opening.heading}</h4>
        {documentToReactComponents(
          JSON.parse(opening.text.raw),
          richTextParseOptions
        )}
      </div>
      <div />
    </section>
  );
};
