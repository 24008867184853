import { BlueGrouping } from "components/color-groupings/blue-grouping/BlueGrouping.component";
import { WhiteGrouping } from "components/color-groupings/white-grouping/WhiteGrouping.component";
import React from "react";
import { AffordingPacBayBody } from "./components/affording-pacbay-body/AffordingPacBayBody.component";
import { AffordingPacBayCta } from "./components/affording-pacbay-cta/AffordingPacBayCta.component";
import { AffordingPacBayHeading } from "./components/affording-pacbay-heading/AffordingPacBayHeading.component";
import { TuitionFaqs } from "./components/tuition-tables/components/tuition-faqs/TuitionFaqs.component";
import { TuitionTables } from "./components/tuition-tables/TuitionTables.component";

export const AffordingPacBay = () => {
  return (
    <main>
      <BlueGrouping>
        <section className="fix-header-overlap affording-pacbay">
          <AffordingPacBayHeading />
          <AffordingPacBayBody />
          <AffordingPacBayCta />
        </section>
      </BlueGrouping>
      <WhiteGrouping>
        <section
          className="layout"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            paddingTop: "6rem",
            paddingBottom: "6rem",
          }}
        >
          <TuitionTables />
          <TuitionFaqs />
        </section>
      </WhiteGrouping>
    </main>
  );
};
